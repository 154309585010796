
@font-face {
  font-family: 'Crimson Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/crimson-pro-v17-latin-regular.eot');
  src: local(''),
       url('../fonts/crimson-pro-v17-latin-regular.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/crimson-pro-v17-latin-regular.woff2') format('woff2'),
       url('../fonts/crimson-pro-v17-latin-regular.woff') format('woff'), 
       url('../fonts/crimson-pro-v17-latin-regular.ttf') format('truetype'), 
       url('../fonts/crimson-pro-v17-latin-regular.svg#CrimsonPro') format('svg'); 
}

@font-face {
  font-family: 'Crimson Pro';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/crimson-pro-v17-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/crimson-pro-v17-latin-500.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/crimson-pro-v17-latin-500.woff2') format('woff2'), 
       url('../fonts/crimson-pro-v17-latin-500.woff') format('woff'), 
       url('../fonts/crimson-pro-v17-latin-500.ttf') format('truetype'), 
       url('../fonts/crimson-pro-v17-latin-500.svg#CrimsonPro') format('svg'); 
}

@font-face {
  font-family: 'Crimson Pro';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/crimson-pro-v17-latin-700.eot'); 
  src: local(''),
       url('../fonts/crimson-pro-v17-latin-700.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/crimson-pro-v17-latin-700.woff2') format('woff2'), 
       url('../fonts/crimson-pro-v17-latin-700.woff') format('woff'), 
       url('../fonts/crimson-pro-v17-latin-700.ttf') format('truetype'), 
       url('../fonts/crimson-pro-v17-latin-700.svg#CrimsonPro') format('svg'); 
}


a,
ins {
    text-decoration: none
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
hr, 
img, 
menu,
nav,
section {
    display: block
}

a,
hr {
    padding: 0
}

a,
sub,
sup {
    vertical-align: baseline
}

.clearBoth,
.clearLine {
    clear: both
}


.social-feed-element em,
mark {
    font-style: italic
}

li {
    list-style: none
}

abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: 0 0;
    box-sizing: border-box
}

ins,
mark {
    background-color: #ff9;
    color: #000
}

blockquote,
q {
    quotes: none
}

blockquote:after,
blockquote:before,
q:after,
q:before {
    content: '';
    content: none
}

a {
    margin: 0;
    font-size: 100%;
    background: 0 0
}

p a {
    color: #0C3250;
    &:hover {
        text-decoration: underline;
    }
}

mark {
    font-weight: 700
}

del {
    text-decoration: line-through
}

abbr[title],
dfn[title] {
    border-bottom: 1px dotted;
    cursor: help
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

hr {
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0
}

input,
select {
    vertical-align: middle
}

sub,
sup {
    font-size: 55%;
    line-height: 0;
    position: relative
}

sup {
    top: -.5em
}

sub {
    bottom: -.25em
}

* {
    box-sizing: border-box
}

*:focus {outline: none!important;}
*:disabled {opacity: .2;}

::selection {
    background: #3d3d3d;
    color: #fff
}

::-moz-selection {
    background: #3d3d3d;
    color: #fff
}

.overflow,
figure {
    overflow: hidden
}

img {display:block;max-width: 100%; height: auto;}

body,
html {
	position: relative;
    line-height: 1;
    background: #F7F7F7;
	color: #000;
    font-size: 16px;
    font-family: 'Crimson Pro', sans-serif;
	text-align: center;
	@media (max-width: 767px){
		&.navOpened {overflow: hidden;}
	}
}
body{
	min-height: 100%;
	padding-bottom: 50px;
	&.startseite {padding-bottom: 0;}
	&::before {
		position: absolute; top: 20px; left: 20px; right: 20px; bottom: 20px; content:'';
		@media (max-width: 1399px){top: 0; left: 0; right: 0; bottom: 0;}
	}
	&.ueber_uns::before {background: #cec0ae url(../img/bg_ueber_uns.jpg) no-repeat -155px 260px; background-size: 1200px;}
	&.denkmalschutz::before {
		background: #cec0ae url(../img/bg_denkmalschutz.svg) no-repeat left top; background-size: 550px;
		@media (max-width: 1399px){background-position: -140px -274px; background-size: 500px;}
	}
	&.standort_nuernberg::before {
		background: #cec0ae url(../img/standort_nuernberg/HG_kartenuernberg.png) no-repeat left top; background-size: 1800px;
		@media (max-width: 1399px){background-position: -140px -274px; background-size: 500px;}
	}
	&.aktuelle_objekte::before, &.vermietungen::before {
		background: #cec0ae ;
	}
	&.referenzen::before {background: #cec0ae url(../img/bg_referenzen.jpg) repeat left top;}
	&.referenzen.alexanderstrasse_30::before {background: #cec0ae url(../img/bg_alexanderstrasse_30.jpg) repeat left top;}
	&.referenzen.rangaustrasse_31::before {background: #cec0ae url(../img/rangaustrasse_31/HG_Rangaustrasse.jpg) repeat left top;}
	&.referenzen.hindenburgstrasse_4a::before {background: #cec0ae url(../img/bg_hindenburgstrasse_4a.jpg) repeat left top;}
	&.referenzen.kreutzerstrasse_23::before {background: #cec0ae url(../img/bg_kreutzerstrasse_23.jpg) repeat left top;}
	&.referenzen.obere_fischerstrasse_4::before {background: #cec0ae url(../img/bg_obere_fischerstrasse_4.jpg) repeat left top;}
	&.referenzen.blumenstrasse_32::before {background: #cec0ae url(../img/bg_blumenstrasse_32.jpg) repeat left top;}
	&.referenzen.pfisterstrasse_6::before {background: #cec0ae url(../img/bg_pfisterstrasse_6.jpg) repeat left top;}
	&.kontakt::before {background: #cec0ae url(../img/bg_kontakt.jpg) repeat left center; top: 0; left: 0; right: 0; bottom: 0;}
	
	&.referenzen.alexanderstrasse_30::after {position: absolute; top: 475px; left: 20px; right: 20px; height: 134px; background: url(../img/bordueren/borduere_alexanderstr_weiss.svg) no-repeat left top; content:'';}
	&.referenzen.hindenburgstrasse_4a::after {position: absolute; top: 455px; left: 20px; right: 20px; height: 148px; background: url(../img/bordueren/borduere_hindenburg_weiss.svg) no-repeat left top;  background-size: 3300px; content:'';}
	&.referenzen.kreutzerstrasse_23::after {position: absolute; top: 470px; left: 20px; right: 20px; height: 163px; background: url(../img/bordueren/borduere_kreutzerstr_weiss.svg) no-repeat left top; background-size: 3700px; content:'';}
	&.referenzen.obere_fischerstrasse_4::after {position: absolute; top: 465px; left: 20px; right: 20px; height: 157px; background: url(../img/bordueren/borduere_obere-fischerstr_weiss.svg) no-repeat left top; background-size: 3400px; content:'';}
	&.referenzen.blumenstrasse_32::after {position: absolute; top: 465px; left: 20px; right: 20px; height: 185px; background: url(../img/bordueren/borduere_blumenstr_weiss.svg) no-repeat left top; background-size: 3900px; content:'';}
	&.referenzen.pfisterstrasse_6::after {position: absolute; top: 487px; left: 20px; right: 20px; height: 110px; background: url(../img/bordueren/borduere_pfisterstr_weiss.svg) no-repeat left top; background-size: 3150px; content:'';}
	&.referenzen.rangaustrasse_31::after {position: absolute; top: 487px; left: 0; right: 0; height: 183px; background: url(../img/rangaustrasse_31/Deko_Rangaustrasse.svg) no-repeat left top; background-size: 1860px; content:'';}
	@media (max-width: 1399px){&::after {display: none;}}
}

h3 {font-size: 20px; line-height: 30px; margin: 0;}


p {margin-bottom: 30px; font-size: 20px; line-height: 30px;}

input,textarea {width: 100%; border: 1px solid #8C704B; border-radius: 0; margin-bottom: 20px; padding: 10px; font-family: 'Crimson Pro', sans-serif; font-size: 20px; -webkit-appearance: none; -webkit-box-shadow: none; box-shadow: none; transition: border-color .3s ease-in-out;}
textarea {height: 172px; overflow: hidden; resize: none;}
.error {border-color: #FF0000; color: #FF0000;}
label.error {position:absolute; left: -10000px;}

.clearLine {clear: both;}
.line20 {height: 20px;}
.posRel {position: relative;}
.mt20 {margin-top: 20px!important;}
.mt30 {margin-top: 30px!important;}
.mt40 {margin-top: 40px!important;}
.mt50 {margin-top: 50px!important;}
.mb0 {margin-bottom: 0!important;}
.mb20 {margin-bottom: 20px!important;}
.mb30 {margin-bottom: 30px!important;}
.mb50 {margin-bottom: 50px!important;}
.mb80 {margin-bottom: 80px!important;}
.mb120 {margin-bottom: 120px!important;}
.mv15 {margin: 15px 0;}
.ph30 {padding: 0 30px;
	@media (max-width: 489px){padding: 0;}
}
.text400 {font-weight:400;}
.textGold {color:#8C704B;
	a {color: #8C704B;}
}
.textWhite {color: #fff;}
.textCenter {text-align: center;}
.textBig {font-size: 28px; line-height: 34px;
	@media (max-width: 489px){font-size: 26px; line-height: 32px;}
}
.whiteBox {background: #fff;}
.indexZ6 {position: relative; z-index: 6;}
.floatImg {float: left; margin: -20px 30px 20px 0;
	.denkmalschutz & {margin: 0 30px 20px 0;}
	/*.standort_nuernberg & {margin: -20px 30px 20px 0;}*/
}

.row {position: relative; margin: 0 auto; max-width: 1298px; text-align: left;}
.col100 {padding: 0 15px; width: 100%;}
.col50 {float: left; padding: 0 15px; width: 50%;
	&.contactLeftCol {width: 312px;}
	&.contactRightCol {width: calc(100% - 312px);}
	@media (max-width: 767px){
		width: 100%!important;
	}
}
.col33 {float: left; padding: 0 15px; width: 33.33%;}

.flexBox {
	display: flex;
	flex-wrap: wrap;
}
.flexCenterV {align-items:center;}

.flexEnd {align-items: flex-end;}
.textBox.whiteBox {padding: 40px 20px 20px 20px;}
.textSmall {display: block; font-size: 10px;}
.textUnderline {text-decoration: underline;}

.height600 {min-height: 600px;}
.box450 {margin-left: auto; margin-right: auto; display: block; max-width: 450px; text-align: left;}
.box600 {margin-left: auto; margin-right: auto; display: block; max-width: 600px; text-align: left;}
.img200 {display: block; margin: 0 auto; width: 200px;}


.lineGold {height:2px; width: 100%; background: #8c704b; margin-bottom: 50px;}
.boderH {padding-top: 25px; border-top: 2px solid #8C704B; border-bottom: 2px solid #8C704B;}


.button {position: absolute; bottom: 30px; left: 30px; display: inline-block; padding: 14px 35px; background: #5A3C14; color: #fff; font-size: 20px; line-height: 30px; transition: background .3s ease-in-out;
	&:hover {background: #8C704B;}
}

#page {position: relative; width: 100%; overflow: hidden; z-index: 4;}
	#pageBox {position: relative; margin: 0 auto; max-width: 1328px; padding: 0 15px;
		.startseite & {
			.startImg {padding: 0 0 36.82% 0; background: url(../img/stilbauten_startseite.jpg) no-repeat center bottom; background-size: contain;}
			@media (min-width: 1701px){
				min-height: 100vh;
				padding-bottom: 528px;
				.startImg {position: absolute; bottom: 0; left: 15px; width: 100%;}
			}
			@media (min-width: 1921px){
				.startImg {padding: 0; height: calc(100vh - 550px); min-height: 486px; background: url(../img/stilbauten_startseite_big.jpg) no-repeat center top; background-size: cover;}
			}
		}
		@media (max-width: 1700px){
			padding: 0 0 260px 0;
		}
	}

#openNav {position: absolute; top:46px; right: 46px; width: 25px; height: 25px; cursor: pointer;
	transition: transform .3s ease-in-out;
	&:hover {transform: scale(1.1);}
	&::before, &::after, span {display: block; width: 100%; height: 2px; background: #8C704B; margin-bottom: 6px; content: '';
		.kontakt &, .referenzen & {background: #fff;}
	}
	@media (max-width: 1399px){ top: 35px; right: 35px;}
}

#simpleNav {position: absolute; bottom: 62px; right: 73px; text-align: left; z-index: 5;
	a {display: block; margin-top: 18px; color:#5A3C14; text-decoration: none; font-size: 20px;
		.kontakt &, .referenzen & {color: #fff;}
		&:hover, &.current {text-decoration: underline;}
	}
	@media (max-width: 1700px){
		position: absolute; bottom: 45px; right: 30px;
	}
}

#prevPageArrow {
	position: absolute; top: 515px; left: 60px; display: inline-block; height: 54px; line-height: 54px; padding: 0 18px 0 25px; color:#5A3C14; text-decoration: none; font-size: 20px;
	transition: transform .3s ease-in-out;
	&:hover {transform: translateX(-10px);}
	span {position: relative; z-index: 6;}
	#prevPageArrowWhite {
		position: absolute; top: 0; right: 0; width: 100%; height: 100%; z-index: 5;
		&::before {position: absolute; top: 0; right: 0; width: 100%; height: 100%; clip-path: polygon(0 27px, 13px 0, 100% 0, 100% 54px, 13px 54px); background: #fff; content:''; }
	}
	#prevPageArrowShadow {
		position: absolute; top: 0; right: 0; width: 100%; height: 100%; z-index: 4; filter:blur(3px);
		&::after {position: absolute; top: 0; right: 0; width: 100%; height: 100%; clip-path: polygon(0 27px, 13px 0, 100% 0, 100% 54px, 13px 54px); background: #333; opacity: .2; content:'';}
	}
	@media (max-width: 1870px){
		display: none;
	}
}

#nextPageArrow {
	position: absolute; top: 515px; right: 60px; display: inline-block; height: 54px; line-height: 54px; padding: 0 25px 0 18px; color:#5A3C14; text-decoration: none; font-size: 20px;
	transition: transform .3s ease-in-out;
	&:hover {transform: translateX(10px);}
	span {position: relative; z-index: 6;}
	#nextPageArrowWhite {
		position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 5;
		&::before {position: absolute; top: 0; left: 0; width: 100%; height: 100%; clip-path: polygon(0 0, 102px 0,100% 27px,102px 54px, 0 54px); background: #fff; content:''; }
	}
	#nextPageArrowShadow {
		position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 4; filter:blur(3px);
		&::after {position: absolute; top: 0; left: 0; width: 100%; height: 100%; clip-path: polygon(0 0, 102px 0,100% 27px,102px 54px, 0 54px); background: #333; opacity: .2; content:'';}
	} 
	@media (max-width: 1870px){
		display: none;
	}
}
.ueber_uns #nextPageArrow #nextPageArrowWhite::before, .ueber_uns #nextPageArrow #nextPageArrowShadow::after {clip-path: polygon(0 0, 156px 0,100% 27px,156px 54px, 0 54px);}
.denkmalschutz #nextPageArrow #nextPageArrowWhite::before, .denkmalschutz #nextPageArrow #nextPageArrowShadow::after {clip-path: polygon(0 0, 180px 0,100% 27px,180px 54px, 0 54px);}
.referenzen #nextPageArrow #nextPageArrowWhite::before, .referenzen #nextPageArrow #nextPageArrowShadow::after {clip-path: polygon(0 0, 94px 0,100% 27px,94px 54px, 0 54px);}
/*.standort_nuernberg #nextPageArrow #nextPageArrowWhite::before, .standort_nuernberg #nextPageArrow #nextPageArrowShadow::after {clip-path: polygon(0 0, 162px 0,100% 27px,162px 54px, 0 54px);}*/
.standort_nuernberg #nextPageArrow #nextPageArrowWhite::before, .standort_nuernberg #nextPageArrow #nextPageArrowShadow::after {clip-path: polygon(0 0, 119px 0,100% 27px,119px 54px, 0 54px);}
.aktuelle_objekte #nextPageArrow #nextPageArrowWhite::before, .aktuelle_objekte #nextPageArrow #nextPageArrowShadow::after {clip-path: polygon(0 0, 143px 0,100% 27px,143px 54px, 0 54px);}
.vermietungen #nextPageArrow #nextPageArrowWhite::before, .vermietungen #nextPageArrow #nextPageArrowShadow::after {clip-path: polygon(0 0, 118px 0,100% 27px,118px 54px, 0 54px);}


#navBox {
	position: fixed; top: 0; right: 0; border-left: 2px solid #5A3C14; width: 280px; height: 100vh; overflow: auto; background: #fff; text-align: left; z-index: 10; transform: translateX(300px); transition: transform .3s ease-in-out;
	.navOpened & {transform: translateX(0);}
	#navBoxContent {position: relative; padding: 0 0 456px 0; min-height: 100%;}
	#navBoxClose {position: absolute; top: 37px; right: 47px; width: 18px; height: 18px; overflow: hidden; cursor: pointer; z-index: 13;
	transition: transform .3s ease-in-out;
		&:hover {transform: scale(1.2);}
		&::before, &::after {position: absolute; top: 50%; left: -2px; width: 20px; height: 2px; background: #8C704B; content: '';}
		&::before {transform: rotate(45deg);}
		&::after {transform: rotate(-45deg);}
	}
	#navBoxLinks {padding: 35px 0 0 40px; font-size: 20px;
		#navBoxTitle {padding-bottom: 13px; font-weight: 700;}
		a {position: relative; display: block; line-height: 33px; font-size: 20px; color: #5A3C14; text-decoration: none; z-index: 11;
			&:hover, &.current {text-decoration: underline;}
			&.navWithSub {float: left;}
		}
		.openSubNav {position: relative; float: left; display: block; width: 33px; height: 33px; cursor: pointer; transition: transform .3s ease-in-out;
			&::after {
				position: absolute;
				top: 12px; left: 10px;
				width: 0;
				height: 0;
				border-left: 6px solid transparent;
				border-right: 6px solid transparent;
				border-top: 10px solid #5A3C14;
				content:'';}
		}
		ul{
			li {clear: left;
				&.opened {
					.openSubNav {transform: rotate(180deg);}
				}
				&.current {
					ul {display: block;}
				}
			}
			ul {display: none; clear: left; margin-left: 14px;}
		}

	}
	#navBoxContact {
		position: absolute; bottom: 0; left: 0; padding: 65px 0 0 45px; width: 100%; background: #CEC0AE;
		&::before {
			display: none; position: absolute; top: -130px; left: 50%; margin-left: -50px; width: 100px; height: 100px; background: url(../img/rosette_small.svg) no-repeat center center; background-size: 100px; content:'';
		.navIconVisible & {display: block;}
		}
	}
	.iconLine {
		display: block; position: relative; padding-left: 33px; line-height: 30px; font-size: 20px; color: inherit; text-decoration: none;
		img {position: absolute; left: 0; top: 6px; }
		&.mailLine {margin-bottom: 33px;}
		&:hover {text-decoration: none;}
	}
	.copyLine {font-size: 16px;}
	#contactNav {padding-bottom: 25px;
		a {display: block; margin-top: 18px; color:#5A3C14; text-decoration: none; font-size: 20px;
			&:hover, &.current {text-decoration: underline;}
		}
	}
}

h1,h2 {position: relative; font-size: 46px; font-weight: 500; z-index: 5;
		@media (max-width: 1339px){
			padding: 0 35px;
		}
		@media (max-width: 767px){
			padding: 0 40px;
		}
		@media (max-width: 479px){
			padding: 0 30px;
			font-size: 32px;
		}
	}

#pageHeader {position: relative; padding: 115px 0 20px 0;
	.referenzen & {padding-top: 70px;}
	.kontakt & {padding-top: 70px;}
	@media (max-width: 991px){
		padding: 245px 0 20px 0;
	}
}
#pageLogo {
	position: absolute; top: 0; right: 0; width: 235px; height: 123px;
	img {width: 235px;}
	@media (max-width: 1339px){
		right: 35px;
	}
	@media (max-width: 767px){
		display: none;
	}
}

/* startseite */
#pageHeaderSiegel {position: absolute; top: 0; left: 0; margin-left: -400px; width: 600px; height: 265px; z-index: 4;
	img {position: absolute; bottom: 0; right: 0; width: 530px; max-width: 530px;}
	@media (max-width: 767px){
		position: absolute; top: auto; bottom: 0; width: 100%; margin: 0; overflow: hidden;
		img {top: 75px; bottom: 0; left: 50%; margin-left: -265px;}
	}
}
#signatureBox {position: absolute; right: 20px; bottom: -75px; max-width: 740px;
	@media (max-width: 767px){
		width: 100%; right: 0; text-align: center;
		img {display: block; margin: 0 auto; width: 500px;}
	}
	@media (max-width: 560px){
		bottom: -82px;
		img {width: 300px;}
	}
}

/* denkmalschutz */
#afaIcon {position: absolute; top: 30px; right: 45px; width: 166px; height: 166px;
	img {width: 166px;}
}

#infoColBox {position: absolute; top: 242px; left: 0; padding: 0 37px; width: 100%; text-align: center;
	.col100, .col50, .col33 {position: relative; display: flex; padding-top: 18px; padding-bottom: 18px; margin-bottom: 16px; align-items: center; justify-content: center; color: #fff; z-index: 10; font-size: 20px; line-height: 31px;
		&.infoCellBoxBig {font-size: 28px; line-height: 34px; padding-top: 34px; padding-bottom: 34px;}
		&::before {position: absolute; top: 0; left: 7px; right: 7px; bottom: 0; background: #5A3C14; opacity: .8; content: ''; z-index: -1;}
	}
	@media (max-width: 991px){
		position: relative; top: auto; left: auto; margin: 25px -7px 0 -7px; padding: 0; width: auto;
	}
	@media (max-width: 767px){
		.col50, .col33 {width: 50%!important;}
		.lastCol {width:100%!important;}
	}
	@media (max-width: 489px){
		.col50, .col33 {width:100%!important;}
	}
}

/* kontakt */
#formSubmit {clear: left; float: right; width: 127px; padding: 15px; border-color: #5A3C14; background: #5A3C14; color: #fff; cursor: pointer; transition: background .3s ease-in-out;
	&:disabled {cursor: default;}
	&:hover {background: #8C704B;}
	&:disabled:hover {background: #5A3C14;}
}
.checkButtonBox {position: relative; width: 100%; margin-bottom: 15px; padding-left: 30px; font-size: 15px; line-height: 18px; text-align: left;}
	.checkButtonBox a {color: inherit; text-decoration: underline;}
	.checkButtonBox  span {position: absolute; top: 0; left: 0; display: block; width: 18px; height: 18px; background: #fff; border: 1px solid #707070; cursor: pointer;}
		.checkButtonBox  span::before {display: none; position:absolute; top: 9px; left: 2px; width: 6px; height: 2px; background: #707070; transform: rotate(45deg); content: '';}
		.checkButtonBox  span::after {display: none; position:absolute; top: 7px; left: 4px; width: 11px; height: 2px; background: #707070; transform: rotate(-45deg); content: '';}
		.checkButtonBox  span.checked::before, .checkButtonBox  span.checked::after {display: block;}
	
.infoMailError {margin-top: 20px; padding: 20px; color: #fff; background: red;}
.infoMailSuccess {margin-top: 20px; padding: 20px; color: #fff; background: #8C704B;}	

#map {
	position: relative; width: 100%; height: 320px; background: #cec0ae; overflow: hidden;
	#mapInfo {
		position: absolute; top: 50%; left: 50%; width: 80%; transform: translate(-50%,-50%); text-align: center; z-index: 3;
		p { line-height: 1.1;
			a {color: inherit; text-decoration: underline;}
		}
		strong {font-size: 24px; cursor: pointer;}
		span {font-size: 15px;}
	}
	#mapBox {display: none; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: #cec0ae; z-index: 5;}
}

/* referenzen */
.swiper-pagination-bullet {background: #fff!important;}

.contentTitleLine {position: relative; width: 100%; color: #5A3C14; text-align: center;
	&::before {position: absolute; top: 50%; left: 0; width: 100%; height: 2px; background: #5A3C14; content:''; z-index: 7;}
	& > span, & > div {position: relative; display: inline-block; padding: 0 24px; background: #fff; font-size: 20px; z-index: 8;}
	&.counterLine > div {padding-left: 90px;}
	&.counterLine.noCounter > div {padding-left: 24px;}
	& > div span {position: absolute; top: 50%; left: 30px; margin-top: -33px; font-size: 60px;}
	&.goldLine {
		&::before {background: #8C704B;}
	}
}
.projectInfo {border-bottom: 2px solid #5A3C14; padding-bottom: 20px; color: #8C704B; text-align: center; line-height: 41px; font-size: 28px;}
.galleryLinks {
	float: left; width: 50%; padding: 20px;
	&.width100 {width: 100%;}
	@media (max-width: 991px){
		width: 100%;
		&.colLeft {padding-bottom: 0;}
		&.colRight {padding-top: 0;}
	}
	a {display: block; padding: 12px 0; line-height: 30px; text-align: center; font-size: 20px; color: #8C704B;
		&:hover {text-decoration:underline;}
	}
}
.buttonPDF {margin: 0 auto; display: inline-block; padding: 12px 0; width: 174px; line-height: 30px; background: #5A3C14; color: #fff; text-align: center; transition: background .3s ease-in-out;
	&:hover {background: #8C704B;}
}
.gallery {position: relative; margin: 0 -4px; padding: 35px 0;
	.galleryCounter {position: absolute; right: 14px; bottom: 45px; padding: 13px 0 0 5px; width: 46px; height: 46px; background: url(../img/referenzbilderanzahl.svg) no-repeat center center; background-size: 46px; color: #fff; font-size: 15px; text-align: center;}
}
.galleryBox {float: left; width: 16.66%; padding: 0 4px;
	@media (max-width: 767px){
		width: 33.33%;
		&.lastItems {display: none;}
	}
	img {display: block; max-width: 100%;}
}
.col2gallery {
	padding-right: 50%;
	min-height: 980px;
	width: 100%;
	@media (max-width: 767px){
		padding-right: 0;
	}
}

.refSlider {
	position: absolute; top: 40px; right: 0; padding: 0 35px 0 15px; width: 50%;
	.standort_nuernberg & {position: relative; top: auto; right: auto; width: 100%; padding: 0;}
	@media (max-width: 767px){
		position: relative; top: auto; right: auto;
		padding: 55px 0 0 0;
		width: 100%;
	}
}

.listPoint {font-size: 20px; display: inline-block; margin: 0 auto; text-align: left;
	li {position: relative; padding-left: 16px; line-height: 30px;
		&::before {position: absolute; top: 14px; left: 0; width: 6px; height: 6px; border-radius: 100%; background: #8c704b; content: '';}
	}
}


.carousel__button svg {stroke: #5A3C14!important;}
.carousel__button.is-prev svg, .carousel__button.is-next svg {stroke: none; stroke-width: 0;}


/* datenschutz */
.contentList {margin-bottom: 30px; padding-left: 20px;
	li {line-height: 30px; font-size: 20px; list-style-type: circle;}
}

/* start Ebene */
#backgroundBox {position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: #F7F7F7; z-index: 30;
	#firstBorderBox {position: absolute; top: 10px; right: 10px; left: 10px; bottom: 10px; border: 3px solid #88714F;
		#secondBorderBox {position: absolute; top: 10px; right: 10px; left: 10px; bottom: 10px; border: 1px solid #88714F;
			#logoTextBox {
				position: absolute; top: 50%; left: 50%; margin: -218px 0 0 -182px; width: 364px; height: 436px;
				img {margin-bottom: 60px;}
				p {font-size: 22px; margin: 0;}
				@media (max-width: 767px){
					margin: -160px 0 0 -130px; width: 260px; height: 320px;
					img {margin-bottom: 40px;}
				}
			}
		}
	}
}
@media (min-width: 768px){
	.toggleBox {position: relative; padding-left: 180px}
	.toggleCounter {position: absolute; top: -28px; left: 10px; width: 150px; height: 150px; font-size: 155px;}
}
@media (max-width: 767px){
	.toggleCounter {font-size: 28px; line-height: 34px; text-decoration: underline;}
}
.toggleTitle {padding-top: 40px; padding-bottom: 50px;}
.toggleButton {text-align: center; cursor: pointer;
	span {position: relative; display: inline-block; width: 12px; height: 12px; margin: 0 auto; transition: transform .3s ease-in-out;
		&::before {position: absolute; top: 0;left: 0; content:''; width: 0;
		  height: 0;
		  border-left: 6px solid transparent;
		  border-right: 6px solid transparent;
		  border-top: 12px solid #8c704b;}
	}
	.opened & {
		span {transform: rotate(180deg);}
	}
}